<template>
  <div class="bigcard-item-row" :style="{ backgroundColor: bigCardConfig.cardBackground }">
    <div class="bigcard-item-theme" :class="bigCardConfig.contentClass" @click="handleClick">
      <div class="bigcard-item-sources">
        <img v-lazy="item.headImg?.[0]" alt="">
      </div>
      <div class="bigcard-content-row"
        :style="{ backgroundColor: bigCardConfig.cardContentBackground }">
        <h3 class="bigcard-item-title">
          {{ item.title }}
        </h3>
        <div class="bigcard-item-content">
          {{ item.channel }}
        </div>
        <div class="bigcard-item-date">
          <img src="@/assets/news/dates.png" alt="">
          {{ $global.formatDate($global.dateStringToTimestamp(item.published), 'MM-dd-yyyy') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/module/bigcard/theme_1.scss';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    bigCardConfig: {
      type: Object,
      required: true,
    }
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
